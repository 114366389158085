import React from 'react';
import { IconBrandWhatsapp } from '@tabler/icons-react';
import { Tooltip } from '@mantine/core';
const WhatsappShare = ({
  url
}: any) => {
  const style = {
    cursor: 'pointer'
  };
  const handleShare = (e: any) => {
    e.preventDefault();
    window.open(`https://wa.me/?text=${encodeURIComponent(url)}`, '_blank');
  };
  return <div onClick={handleShare} data-sentry-component="WhatsappShare" data-sentry-source-file="WhatsappShare.tsx">
      <Tooltip label='whatsapp' data-sentry-element="Tooltip" data-sentry-source-file="WhatsappShare.tsx">
        <IconBrandWhatsapp size={20} style={style} data-sentry-element="IconBrandWhatsapp" data-sentry-source-file="WhatsappShare.tsx" />
      </Tooltip>
    </div>;
};
export default WhatsappShare;