import React from 'react';
import { IconBrandFacebook } from '@tabler/icons-react';
import { Tooltip } from '@mantine/core';
const FacebookShare = ({
  url
}: any) => {
  const style = {
    cursor: 'pointer'
  };
  const handleShare = (e: any) => {
    e.preventDefault();
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
  };
  return <div onClick={handleShare} data-sentry-component="FacebookShare" data-sentry-source-file="FacebookShare.tsx">
      <Tooltip label='facebook' data-sentry-element="Tooltip" data-sentry-source-file="FacebookShare.tsx">
        <IconBrandFacebook size={20} style={style} data-sentry-element="IconBrandFacebook" data-sentry-source-file="FacebookShare.tsx" />
      </Tooltip>
    </div>;
};
export default FacebookShare;