"use client";

import { Popover, ActionIcon, Box, Group, Tooltip } from '@mantine/core';
import { IconShare2 } from '@tabler/icons-react';
import React, { useState } from 'react';
import ClipboardCopy from './ClipBoardCopy';
import FacebookShare from './FacebookShare';
import WhatsappShare from './WhatsappShare';
import LinkedInShare from './LinkedInShare';
interface ShareOptionsProps {
  url: string;
  title?: string;
  thumbnailUrn: string;
  description?: string;
  email?: string;
}
export const ShareOptions: React.FC<ShareOptionsProps> = ({
  url,
  title,
  thumbnailUrn,
  email,
  description
}) => {
  const [opened, setOpened] = useState(false);
  return <Popover opened={opened} onChange={setOpened} position="bottom" withArrow shadow="md" data-sentry-element="Popover" data-sentry-component="ShareOptions" data-sentry-source-file="ShareOptions.tsx">
      {/* Share Button */}
      <Popover.Target data-sentry-element="unknown" data-sentry-source-file="ShareOptions.tsx">
        <Tooltip label='Share' data-sentry-element="Tooltip" data-sentry-source-file="ShareOptions.tsx">
          <ActionIcon onClick={() => setOpened(prev => !prev)} variant="subtle" data-sentry-element="ActionIcon" data-sentry-source-file="ShareOptions.tsx">
            <IconShare2 size={20} data-sentry-element="IconShare2" data-sentry-source-file="ShareOptions.tsx" />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      {/* Popover Content */}
      <Popover.Dropdown data-sentry-element="unknown" data-sentry-source-file="ShareOptions.tsx">
        <Box data-sentry-element="Box" data-sentry-source-file="ShareOptions.tsx">
          <Group align="center" data-sentry-element="Group" data-sentry-source-file="ShareOptions.tsx">
            {/* WhatsApp Share */}
            <WhatsappShare url={url} data-sentry-element="WhatsappShare" data-sentry-source-file="ShareOptions.tsx" />

            {/* Facebook Share */}
            <FacebookShare url={url} data-sentry-element="FacebookShare" data-sentry-source-file="ShareOptions.tsx" />

            {/* LinkedIn Share */}
            <LinkedInShare url={url} email={email} title={title} thumbnailUrn={thumbnailUrn} description={description} data-sentry-element="LinkedInShare" data-sentry-source-file="ShareOptions.tsx" />

            {/* Clipboard Copy */}
            <ClipboardCopy url={url} data-sentry-element="ClipboardCopy" data-sentry-source-file="ShareOptions.tsx" />
          </Group>
        </Box>
      </Popover.Dropdown>
    </Popover>;
};